import Battery50Icon from '@mui/icons-material/Battery50';
import { Gauge } from '@mui/x-charts';
import Box from '@mui/material/Box';
import { Card, Col, Container, Row } from 'react-bootstrap';

export function DashboardCard({ title, value, logo } : { title: string, value: any, logo: React.ReactNode }) {
    return (
        <Card className="table-border mb-2">
            <Card.Body>
                <Container>
                    <Row className="align-items-start justify-content-between">
                        <Col>
                            <Card.Text className="text-muted text-uppercase">
                                {title}
                            </Card.Text>
                            <Card.Title className="fs-2">{value}</Card.Title>
                        </Col>
                        <Col xs="auto">
                            {logo}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
}
