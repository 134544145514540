import { useInjection } from "inversify-react";
import { API } from "../../services/HTTP";
import { useEffect, useState } from "react";
import { Battery } from "../../models/Battery";
import { Col, Row, Spinner } from "react-bootstrap";
import { Device } from "../../models/Device";
import { mean, min, sum } from "lodash";
import { DashboardCard } from "../DashboardCard";
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';

export function MainView({ domain_id, selectedBattery, cells } : { domain_id: string, selectedBattery: Battery, cells: Device[] }) {

    const api = useInjection<API>("API");

    const [loading, setLoading] = useState(false);

    const [currentVoltage, setCurrentVoltage] = useState<number>();
    const [currentCurrent, setCurrentCurrent] = useState<number>();
    const [currentPower, setCurrentPower] = useState<number>();
    const [currentSoC, setCurrentSoC] = useState<number>();

    // Could also just get cell_ids associated to battery and fetch one cell at a time if too slow
    useEffect (() => {

        setLoading(true);

        const fetchCells = async () => {
            if (cells) {
                try {

                    const voltages: number[] = [];
                    const currents: number[] = [];
                    const socs: number[] = []; 

                    cells.forEach( (cell: Device) => {
                        const { voltage, current, soc } = cell.data || {};

                        if (voltage != null) voltages.push(+voltage);
                        if (current != null) currents.push(+current);
                        if (soc != null) socs.push(+soc);
                    })

                    const voltage = sum(voltages);
                    const current = mean(currents);
                    const power = voltage * current;

                    setCurrentVoltage(+voltage.toFixed(2));
                    setCurrentCurrent(+current.toFixed(2));
                    setCurrentSoC(min(socs));
                    setCurrentPower(+power.toFixed(2));

                } catch (error) {
                    console.error('Error fetching cells:', error);
                }
            }
        };
    
        fetchCells().finally(() => setLoading(false));

    }, [selectedBattery, cells])

    return <>
            { selectedBattery && cells && cells.length > 0 ? (
                <>
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <Col className="m-3 d-flex flex-column">
                            {[
                                { 
                                    title: "Nominal Voltage", 
                                    value: selectedBattery?.data.nominal_voltage, 
                                    unit: "V", 
                                    icon: <BoltIcon style={{ fontSize: 60, color: "#acacad" }}/> },
                                { 
                                    title: "Max Current", 
                                    value: selectedBattery?.data.max_current, 
                                    unit: "A", 
                                    icon: <PowerInputIcon style={{ fontSize: 60, color: "#acacad" }}/> },
                                { 
                                    title: "Nominal Capacity", 
                                    value: selectedBattery?.data.nominal_capacity, 
                                    unit: "Ah", 
                                    icon: <BatteryFullIcon style={{ fontSize: 60, color: "#acacad" }}/>
                                },
                            ].map((item, idx) => (
                                <Row key={idx} className="d-flex flex-grow-1">
                                    <DashboardCard
                                        title={item.title}
                                        value={item.value != null ? `${item.value}${item.unit}` : "-"}
                                        logo={item.icon}
                                    />
                                </Row>
                            ))}
                        </Col>
                        <Col className="m-3 d-flex flex-column flex-grow-1">
                            {[
                                { 
                                    title: "Current Voltage", 
                                    value: currentVoltage, 
                                    unit: "V", 
                                    icon: <BoltIcon style={{ fontSize: 60, color: "#acacad" }}/>
                                },
                                { 
                                    title: "Current Current", 
                                    value: currentCurrent, 
                                    unit: "A", 
                                    icon: <PowerInputIcon style={{ fontSize: 60, color: "#acacad" }}/>
                                },
                            ].map((item, idx) => (
                                <Row key={idx} className="d-flex flex-grow-1">
                                    <DashboardCard
                                        title={item.title}
                                        value={item.value != null ? `${item.value}${item.unit}` : "-"}
                                        logo={item.icon}
                                    />
                                </Row>
                            ))}
                        </Col>
                        <Col className="m-3 d-flex flex-column flex-grow-1">
                            {[
                                { 
                                    title: "Current Power", 
                                    value: currentPower, 
                                    unit: "W", 
                                    icon: <PowerIcon style={{ fontSize: 60, color: "#acacad" }}/>
                                },
                                { 
                                    title: "Current SoC", 
                                    value: currentSoC != null ? Math.round(currentSoC) : null, 
                                    unit: "%", 
                                    icon: <Battery50Icon style={{ fontSize: 60, color: "#acacad" }}/>
                                },
                            ].map((item, idx) => (
                                <Row key={idx} className="d-flex flex-grow-1">
                                    <DashboardCard
                                        title={item.title}
                                        value={item.value != null ? `${item.value}${item.unit}` : "-"}
                                        logo={item.icon}
                                    />
                                </Row>
                            ))}
                        </Col>
                    </div>
                </div>
                </>
            ) : (<>No Cells associated to this Battery</>) }
    </>
}