import { useInjection } from "inversify-react";
import { FetchTokenizedTimestreamData } from "../../services/FetchTokenizedTimestreamData";
import { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Battery } from "../../models/Battery";
import { Device } from "../../models/Device";
import electrochemical_model from "../../assets/battery/ECM_from_DRT.png"
import soc_model from "../../assets/battery/Second-order-resistor-capacitor-RC-equivalent-circuit-model-ECM-of-a-battery.png"
import ocv_curve from "../../assets/battery/OCV.jpg"
import { LineChart, ChartsTooltip, ChartContainer, ScatterChart, ResponsiveChartContainer, ScatterPlot, LinePlot, ChartsXAxis, ChartsYAxis, ChartsLegend, axisClasses } from '@mui/x-charts';

interface DataSet {
    x: number[];
    y: number[];
}

export function ModelsView({ domain_id, selectedEISCell } : { domain_id: string, selectedEISCell: Device }) {  
    

    const fetchTimestreamData = useInjection<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData);

    const soc_20: DataSet = {
        x: [0.000256247,0.000261719,0.000271543,0.000283922,0.00030027,0.000313646,0.000324249,0.000332629,0.000335615,0.000339719,0.000342918,0.000346225,0.000348582,0.000354387,0.000357986,0.000359572,0.000366332,0.000372836,0.000381785],
        y: [-0.0000139,0.00000621,0.0000206,0.00003,0.0000409,0.0000342,0.0000289,0.0000234,0.0000194,0.0000161,0.0000141,0.0000123,0.000014,0.0000179,0.0000177,0.0000246,0.0000329,0.0000422,0.0000548]
    }

    const soc_50: DataSet = {
        x: [0.000254256,0.000259814,0.000268806,0.000281148,0.000295757,0.000307922,0.000316275,0.000323363,0.000326253,0.000328839,0.000332233,0.000335821,0.000338049,0.000342699,0.000345746,0.000351259,0.000355924,0.000365716,0.000377145],
        y: [-0.000014023602028079,0.00000507,0.0000186,0.0000267,0.0000372,0.0000305,0.0000251,0.0000204,0.0000166,0.0000146,0.000013,0.0000125,0.0000156,0.0000173,0.0000224,0.0000274,0.0000367,0.0000484,0.0000635]
    }

    const soc_80: DataSet = {
        x: [0.000249852,0.000254843,0.000264146,0.000277085,0.0002914,0.000303843,0.000313758,0.000322823,0.000326813,0.000330209,0.000334189,0.000338891,0.000341263,0.000349468,0.000349413,0.000356746,0.000365031,0.000373966,0.000387803],
        y: [-0.0000169,0.00000443,0.0000183,0.0000269,0.0000385,0.0000335,0.0000278,0.0000237,0.0000195,0.0000166,0.0000157,0.0000143,0.0000192,0.0000221,0.0000262,0.0000345,0.0000437,0.0000574,0.0000757]
    }

    const scientificFormatter = (value: number | null) => { return typeof value === 'number' ? value.toExponential(1) : ''; };

    const [elapsedTime, setElapsedTime] = useState(0);
    const [scatterData, setScatterData] = useState<{ x: any; y: any; id: any; }[]>([]); 

    const getData = useCallback(async () => {
        try {
            if (!domain_id) return;

            const url = `devices/${selectedEISCell.Id}/EISdata`;

            const response = await fetchTimestreamData.fetchTokenizedData(url);
            const { z_real, z_imag } = response;

            const formattedData = z_real.map((xValue, index) => ({
                x: xValue,
                y: z_imag[index],
                id: index
            }))

            console.log("Fetched data:", formattedData);

            setScatterData(formattedData);

        } catch (err) {
            console.error(err);
        } 

    }, [domain_id, selectedEISCell, fetchTimestreamData]);

    useEffect(() => {

        console.log("Elapsed time updated:", elapsedTime);

        const interval = setInterval(() => {
            setElapsedTime(prevTime => prevTime + 2);
            getData();
        }, 2000);
    
        if (elapsedTime >= 240) {
            clearInterval(interval);
        }
    
        return () => clearInterval(interval);

    }, [elapsedTime, getData]);
    
    useEffect(() => {

        console.log("Component mounted or selectedEISCell changed");
        setElapsedTime(0);
        getData();
    }, [selectedEISCell, getData]);

    return <>
        <div className="container mt-4 d-flex flex-column flex-grow-1" style={{ height: '100%' }}>
            <Row className="align-items-stretch gx-3 gy-3">
                {/* Electrochemical and SoC Models */}
                {[{ title: "Electrochemical Model", image: electrochemical_model },
                { title: "SoC Model", image: soc_model }
                ].map((model, idx) => (
                    <Col key={idx} className="m-2 p-4 table-border d-flex flex-column align-items-center justify-content-center">
                        <h4>{model.title}</h4>
                        <img src={model.image} alt={`${model.title} of cell`} className="img-fluid scaled-image" />
                    </Col>
                ))}
            </Row>

            {/* Impedance Chart */}
            <Row className="m-1 table-border d-flex flex-column align-items-center flex-grow-1 justify-content-center">
                <h4>{`EIS of Cell ${selectedEISCell.data!.display_name ?? '-'}`}</h4>
                <div className="w-100 h-100 d-flex flex-column justify-content-center flex-grow-1">
                <ResponsiveChartContainer
                    height={600}
                    sx={{
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                        // Move the y-axis label with CSS
                            transform: 'translateY(-40px)',
                        },
                    }}
                    series={[
                        {
                            type: 'line',
                            data: soc_20.y,
                            label: 'SOC 20',
                            showMark: false,
                            valueFormatter: scientificFormatter
                        },
                        {
                            type: 'line',
                            data: soc_50.y,
                            label: 'SOC 50',
                            showMark: false,
                            valueFormatter: scientificFormatter
                        },
                        {
                            type: 'line',
                            data: soc_80.y,
                            label: 'SOC 80',
                            showMark: false,
                            valueFormatter: scientificFormatter
                        },
                        {
                            type: 'scatter',
                            data: scatterData,
                            label: 'EIS',
                        },
                    ]}
                    xAxis={[
                        {
                            data: soc_20.x,
                            valueFormatter: scientificFormatter,
                            id: 'x-axis-id',
                            tickNumber: 7
                        },
                        {
                            data: soc_50.x,
                            valueFormatter: scientificFormatter,
                            tickNumber: 7
                        },
                        {
                            data: soc_80.x,
                            valueFormatter: scientificFormatter,
                            tickNumber: 7
                        },
                        {
                            data: scatterData,
                            valueFormatter: scientificFormatter,
                            tickNumber: 7
                        }
                    ]}
                    yAxis={[
                        {
                            valueFormatter: scientificFormatter,
                            id: 'y-axis-id',
                            tickNumber: 4,
                        }
                    ]}>
                    <LinePlot />
                    <ScatterPlot />
                    <ChartsXAxis label="Z_real" position="bottom" axisId="x-axis-id" />
                    <ChartsYAxis label="Z_imag" axisId="y-axis-id" />
                    <ChartsTooltip />
                    <ChartsLegend direction="row" />
                </ResponsiveChartContainer>
                </div>
            </Row>
        </div>
    </>
}