import React, { memo, useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { Device } from "../../models/Device";

const MemoizedGaugeChart = memo(GaugeChart);

export function GaugeComponent({ cell }: { cell: Device }) {

    const [percent, setPercent] = useState<number>(
        (Math.min(Math.max(Math.round(cell.data!.temperature), 20), 60) - 20) / 40
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPercent(
                (Math.min(Math.max(Math.round(cell.data!.temperature), 20), 60) - 20) / 40
            );
        }, 5000);

        return () => clearTimeout(timeout);
    }, [cell.data!.temperature]);

    return (
        <div
            style={{
                textAlign: "center",
                marginTop: "10px",
                fontWeight: "bold",
                width: "150px",
            }}
        >
            <MemoizedGaugeChart
                id={`gauge-chart-${cell.name}`}
                hideText={true}
                percent={percent}
                animDelay={0}
            />
        </div>
    );
}
