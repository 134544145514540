import { useCallback, useEffect, useState } from "react";
import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DomainRepository } from "../services/DomainRepository";
import { Domain } from "../models/Domain";
import { UserService } from "../services/User";
import { DomainHeader } from "../components/DomainHeader";
import { Button, Col, Dropdown, Row, Spinner } from "react-bootstrap";
import BatteryGauge from 'react-battery-gauge'
import { Device } from "../models/Device";
import { Battery } from "../models/Battery";
import { MainView } from "../components/battery/MainView";
import { EvolutionView } from "../components/battery/EvolutionView";
import { ModelsView } from "../components/battery/ModelsView";
import { GaugeComponent } from "../components/battery/GaugeComponent";

export function BatteryPage() {
    const api = useInjection<API>("API");
    const user = useInjection<UserService>(UserService);

    const [isAdmin, setIsAdmin] = useState(false);
    const [t, i18n] = useTranslation();

    const [loading, setLoading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
    const [view, setView] = useState<"voltage" | "current" | "SoC" | "SoH" | "temperature">("temperature");

    const domainRepository = useInjection<DomainRepository>(DomainRepository);
    let { domain_id } = useParams();
    const [domain, setDomain] = useState<Domain | null>();

    const [batteries, setBatteries] = useState<Battery[]>([]);
    const [selectedBattery, setSelectedBattery] = useState<Battery>();
    const [cells, setCells] = useState<Device[]>([]);
    const [selectedCellList, setSelectedCellList] = useState<string[]>([]);
    const [selectedEISCell, setSelectedEISCell] = useState<Device>();

    const reload = useCallback(async () => {

        setLoading(true);
        
        const f = async () => {

            if (!domain_id)
                return;
            setDomain(await domainRepository.get(domain_id));

            const response = await api.get<Battery[]>(`domains/${domain_id}/batteries`);
            setBatteries(response.data);

        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    useEffect(() => {

        reload();
        user.isAdmin().then(admin => setIsAdmin(admin));

    }, [reload]);

    useEffect(() => {

        if(batteries && batteries.length > 0) {
            setSelectedBattery(batteries[0])
        }

    }, [batteries]);

    const fetchCells = useCallback(async () => {
        if (selectedBattery) {
            try {
                const response = await api.get<Device[]>(`domains/${domain_id}/batteries/${selectedBattery.id}/cells`);
                setCells(response.data);
    
                if (isFirstLoad) {
                    setSelectedCellList([response.data[0].Id]);
                    setSelectedEISCell(response.data[0]);
                    setIsFirstLoad(false);
                } 
            } catch (error) {
                console.error('Error fetching cells:', error);
            }
        }
    }, [isFirstLoad, selectedBattery, selectedEISCell, api, domain_id]);    

    // Could also just get cell_ids associated to battery and fetch one cell at a time if too slow
    useEffect (() => {

        fetchCells();
        const interval = setInterval(fetchCells, 2000);
        return () => clearInterval(interval);

    }, [batteries, selectedBattery, fetchCells, isFirstLoad])

    const getValue = (cell: Device) => {
        const value = view === "voltage"
        ? cell.data!.voltage ? Math.round(+cell.data!.voltage * 10)/10 : 0
        : view === "current"
        ? cell.data!.current ? Math.round(+cell.data!.current * 10)/10 : 0
        : view === "SoC"
        ? cell.data!.soc ? Math.round(+cell.data!.soc * 100) : 0
        : view === "temperature"
        ? cell.data!.temperature ? Math.round(+cell.data!.temperature*10)/10 : 0
        : 0;
        return value;
    };

    const triggerEIS = async (cell_id: string) => {
        await api.get(`devices/${cell_id}/triggerEIS`);
    }

    return <>
        <DomainHeader
            title={`zBMS Battery Module`}
            domain={domain_id ?? ""}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="d-flex">
                    <Dropdown>
                        <Dropdown.Toggle id="battery-dropdown" variant="outline-secondary" style={{ marginRight: '10px' }}>
                            <i className="bi bi-chevron-down"></i>&ensp;{selectedBattery ? `Battery: ${selectedBattery.id}` : 'Select a Battery'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {batteries.map(battery => (
                                <Dropdown.Item
                                    key={battery.id}
                                    onClick={() => setSelectedBattery(battery)}
                                >
                                    {battery.id}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button disabled={loading} onClick={(e) => { e.preventDefault(); reload(); }} variant="outline-secondary">
                        {loading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status" />
                        ) : (
                            <i className="bi bi-arrow-clockwise"></i>
                        )}
                    </Button>
                </div>
            </div>
        </DomainHeader>

        {loading ? (
            <Spinner animation="border" />
        ) : batteries && batteries.length > 0 && cells && cells.length > 0 ? (<>
            {/* Dashboard */}

            <Row className="gx-3 gy-4" style={{ height: "100%"}}>
                {/* Left Panel */}
                <Col md={7} className="d-flex flex-column">
                    {/* Battery Information Cards */}
                    <Row className="gx-3 gy-3 flex-grow-1">
                        <MainView domain_id={domain_id!} selectedBattery={selectedBattery!} cells={cells!} />
                    </Row>
                    {/* Charts and Controls */}
                    <Row className="mx-2 table-border flex-grow-1">
                        <EvolutionView domain_id={domain_id!} selectedBattery={selectedBattery!} cells={cells!} selectedCellList={selectedCellList} view={view} setView={setView} />
                    </Row>
                </Col>

                {/* Right Panel */}
                <Col md={5} className="d-flex flex-column">
                    <ModelsView domain_id={domain_id!} selectedEISCell={selectedEISCell!} />
                </Col>
            </Row>

            {/* Battery Gauges */}
            <Row className="my-4">
                <Col>
                <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', justifyContent: 'space-evenly', width: '100%' }}>
                        {cells.map((cell) => (
                            <div key={cell.Id} className="d-flex flex-column align-items-center">
                                <div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold' }}>{cell.data!.display_name ?? cell.name}</div>
                                <BatteryGauge
                                    value={cell.data!.soc ? Math.round(+cell.data!.soc) : 0}
                                    orientation="vertical"
                                    size={190}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSelectedCellList(prev => prev.includes(cell.Id) ? prev.filter(item => item !== cell.Id) : [...prev, cell.Id])}
                                    customization={selectedCellList.includes(cell.Id) ?
                                        {
                                            readingText: { lightContrastColor: 'black', darkContrastColor: 'black' },
                                        } :
                                        {
                                            batteryBody: { strokeColor: 'silver' },
                                            batteryCap: { strokeColor: 'silver' },
                                            readingText: { lightContrastColor: 'black', darkContrastColor: 'black' },
                                        }
                                    }
                                />
                                <GaugeComponent cell={cell} />
                                <div style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>{cell.data!.temperature != null ? `${Math.round(+cell.data!.temperature * 10)/10}°C` : "-"}</div>
                                <Button className="my-2" style={{ width: '110px' }} onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedEISCell(cell);
                                    triggerEIS(cell.Id);
                                }}>Trigger EIS</Button>
                                <Button
                                    variant={selectedEISCell!.Id === cell.Id ? cell.data!.latest_EIS ? "success" : "danger" : "outline-primary"}
                                    style={{ width: '110px' }}
                                    onClick={(e) => {e.preventDefault(); setSelectedEISCell(cell);}}
                                >
                                    {selectedEISCell?.Id === cell.Id ? (cell.data!.latest_EIS ? new Date(cell.data!.latest_EIS).toLocaleTimeString('de-CH', { hour: '2-digit', minute: '2-digit' }) : "No EIS") : "View EIS"}
                                </Button>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </>) : (<>No Batteries to Show.</>)}
    </>
}
