import { useInjection } from "inversify-react";
import { FetchTokenizedTimestreamData } from "../../services/FetchTokenizedTimestreamData";
import { Button, Col, Spinner } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import BatteryGauge from "react-battery-gauge";
import { Device } from "../../models/Device";
import { Battery } from "../../models/Battery";
import { BatteryDataDisplay } from "./BatteryDataDisplay";
import { ZoomData } from "@mui/x-charts-pro";
import { debounce } from "lodash";

interface DataState {
    voltageSeries: number[];
    currentSeries: number[];
    socSeries: number[];
    temperatureSeries: number[];
    cellIdData: string[];
    timestampsData: string[];
}

export function EvolutionView({ 
        domain_id, 
        selectedBattery, 
        cells,
        selectedCellList,
        view,
        setView,
    } : {
        domain_id: string;
        selectedBattery: Battery;
        cells: Device[];
        selectedCellList: string[]; 
        view: "voltage" | "current" | "SoC" | "SoH" | "temperature";
        setView: (view: "voltage" | "current" | "SoC" | "SoH" | "temperature") => void;
    }) {

    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const fetchTimestreamData = useInjection<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData);
    const [zoom, setZoom] = useState<ZoomData[]>([
        {
            axisId: 'x-ax',
            start: 70,
            end: 100,
        },
    ]);

    const [lastTimestamp, setLastTimestamp] = useState<number>(Math.floor((Date.now() - 12 * 60 * 60 * 1000) / 1000));
    const [data, setData] = useState<DataState>({
        voltageSeries: [],
        currentSeries: [],
        socSeries: [],
        temperatureSeries: [],
        cellIdData: [],
        timestampsData: [],
    });

    const getData = debounce(async () => {
        
        try {
            if (!domain_id) return;
    
            const url = `domains/${domain_id}/batteries/${selectedBattery.id}/RTdata?start=${lastTimestamp}`;
            const response = await fetchTimestreamData.fetchTokenizedData(url);
            const { voltage, current, soc, temperature, "device-id": cellIdData, timestamps } = response;
    
            const array_length = timestamps.length;
    
            
            if (array_length > 0) {
                setLastTimestamp(Math.round(new Date(timestamps[array_length - 1]).getTime() / 1000));
    
                setData(prevData => ({
                    voltageSeries: [...prevData.voltageSeries, ...voltage],
                    currentSeries: [...prevData.currentSeries, ...current],
                    socSeries: [...prevData.socSeries, ...soc],
                    temperatureSeries: [...prevData.temperatureSeries, ...temperature],
                    cellIdData: [...prevData.cellIdData, ...cellIdData],
                    timestampsData: [...prevData.timestampsData, ...timestamps],
                }));
            } 
    
        } catch (err) {
            console.error(err);
        } finally {
            setInitialLoading(false);
        }
    }, 2000);    

    useEffect(() => { 

        getData();
        const interval = setInterval(getData, 2000);
        return () => clearInterval(interval);

    }, [selectedBattery, lastTimestamp, getData]);

    return (
        <>
        <Col>
            <div className="d-flex justify-content-between mb-3">
                <div className="d-flex">
                    <Button onClick={() => setZoom([{ axisId: 'x-ax', start: 70, end: 100 }])}>
                        Reset zoom
                    </Button>
                </div>
                <div className="d-flex">
                    {["voltage", "current", "SoC", "temperature"].map((type) => (
                        <Button
                            key={type}
                            style={{ marginRight: "10px" }}
                            onClick={(e) => {
                                e.preventDefault();
                                setView(type as any);
                            }}
                            variant={view === type ? "primary" : "outline-primary"}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </Button>
                    ))}
                </div>
            </div>

            {selectedBattery && cells.length > 0 ? (
                <BatteryDataDisplay
                    data={view === "voltage" ? data.voltageSeries
                        : view === "current" ? data.currentSeries
                            : view === "SoC" ? data.socSeries
                                : view === "temperature" ? data.temperatureSeries
                                    : []}
                    cell_ids={data.cellIdData}
                    timestamps={data.timestampsData}
                    selectedCellList={selectedCellList}
                    label={view === "voltage" ? "Voltage (V)"
                        : view === "current" ? "Current (A)"
                            : view === "SoC" ? "SoC (%)"
                                : view === "temperature" ? "Temperature (°C)"
                                    : ''}
                    initialLoading={initialLoading}
                    zoom={zoom}
                    setZoom={setZoom}
                />

            ) : (
                <>No Cells associated to this Battery</>
            )}
        </Col>
        </>
    );
}