import { LineChartPro, ZoomData } from '@mui/x-charts-pro';
import * as React from 'react';
import { LicenseInfo } from '@mui/x-license';
import { useEffect } from 'react';

LicenseInfo.setLicenseKey('c25c5138f519f5427f0f855d3f0ac253Tz0xMDEzNDUsRT0xNzYyNTEwODcyMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

export function BatteryDataDisplay({ 
        data, 
        cell_ids, 
        timestamps, 
        selectedCellList, 
        label,
        initialLoading,
        zoom, 
        setZoom
    }: { 
        data: number[], 
        cell_ids: string[], 
        timestamps: string[], 
        selectedCellList: string[], 
        label: string,
        initialLoading: boolean,
        zoom: ZoomData[],
        setZoom: (zoomData: ZoomData[] | ((zoomData: ZoomData[]) => ZoomData[])) => void
    }) {

    const groupedData: Record<string, { [device_id: string]: number | null }> = {};

    const dict: { [key: string]: string } = {
        "1_B.SXL-083": "0",
        "1_B.SXL-120": "1",
        "1_B.SXL-082": "2",
        "1_B.SXL-086": "3",
        "1_B.SXL-071": "4",
        "1_B.SXL-085": "5",
        "1_B.SXL-098": "6",
        "1_B.SXL-046": "7",
        "1_B.SXL-111": "8",
        "1_B.SXL-107": "9",
        "1_B.SXL-100": "10",
        "1_B.SXL-094": "11",
        "1_B.SXL-109": "12",
        "1_B.SXL-106": "13"
    }

    let keyToLabel: { [key: string]: string } = {}

    for (let i = 0; i < timestamps.length; i++) {

        const cell_id = cell_ids[i]

        if (selectedCellList.includes(cell_id)) {

            const timestamp = new Date(timestamps[i]).toISOString();
            const value = data[i];

            // Initialize timestamp entry if it doesn't exist
            if (!groupedData[timestamp]) {
                groupedData[timestamp] = {};
            }

            groupedData[timestamp][cell_id] = +value;

            keyToLabel[cell_id] = dict[cell_id];
        }
    }

    // Convert the grouped data into an array of objects with the desired format
    const formattedData = Object.entries(groupedData).map(
        ([timestamp, devices]) => ({
            timestamp: new Date(timestamp),
            ...devices,
        })
    );

    return (
        <>
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <LineChartPro
            zoom={zoom}
            onZoomChange={setZoom}
            xAxis={[{
                // @ts-ignore
                zoom: true,
                dataKey: 'timestamp', 
                scaleType: 'time',
                id: 'x-ax'
            }]}
            yAxis={[{ scaleType: 'linear', label: label }]}
            series={Object.keys(keyToLabel).map((key) => ({
                dataKey: key,
                label: keyToLabel[key],
                showMark: false,
                connectNulls: true,
            }))}
            dataset={formattedData.length > 0 ? formattedData : [{}]}
            height={400}
            slotProps={ 
                initialLoading ? { noDataOverlay: { message: 'Data is loading... It can take up to a minute' } } 
                : selectedCellList.length > 0 ? { noDataOverlay: { message: `No data in the last ${12} hours`}} 
                : {}}
        />
        </div>
        </>
    );
}
