import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { useEffect, useState } from "react";

export function IPAddressPage() {

    const api = useInjection<API>("API");

    const [ipAddresses, setIPAddresses] = useState<string[]>([])

    const getIpAddress = async () => {
        const response = await api.get(`/devices/BatteryTest1_B.SXL-996/getIP`);

        return response.data
    }

    useEffect(() => {

        const interval = setInterval(async () => {
            
            const address = await getIpAddress()
            
            setIPAddresses([...ipAddresses, address])

        }, 10000); // 10 seconds

        return () => clearInterval(interval);

    }, [ipAddresses]);

    return <>{ipAddresses}</>

}